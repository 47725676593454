import { useRecoilState, useRecoilValue } from 'recoil'
import { createContext, useContext } from 'react'
import { apiBaseState, currentSessionState } from 'atoms/Link'
import { globalErrorState } from 'atoms/Form'

const RequestsContext = createContext()

export const useRequests = () => {
  return useContext(RequestsContext)
}

export const RequestsProvider = ({ children }) => {
  // Recoil state
  const apiBase = useRecoilValue(apiBaseState)
  const currentSession = useRecoilValue(currentSessionState)
  const [globalError, setGlobalError] = useRecoilState(globalErrorState)

  /**
   * Build bearer token
   */
  const getBearerToken = () => {
    // Get session ID and integration Key from local context
    const sessionId = currentSession.sessionId
    const integrationKey = currentSession.integrationKey

    // Return token
    return `Bearer ${integrationKey}:${sessionId}`
  }

  /**
   * Make a request to the API
   * @param {string} method - request method: POST, GET, PUT, DELETE
   * @param {string} endpoint - API endpoint
   * @param {object} payload - body payload to send to the server
   * @param {string} token - access token to send in the Bearer header
   * @param {boolean} ignoreResponse - don't check or return any data
   * @param {object} headers - additional headers to send with the request
   * @param {string} contentType - content type to send in headers (defaults to 'application/json')
   * @returns
   */
  const makeRequest = async ({
    method = 'POST',
    endpoint,
    payload,
    token,
    ignoreResponse,
    headers,
    contentType = 'application/json',
    file,
  }) => {
    const controller = new AbortController();
    const { signal } = controller;

    const timeoutId = setTimeout(() => {
        controller.abort('request_timeout');
    }, 59000);  // 59 sec = 1 sec less than the server

    // Request next link update
    const response = await fetch(`${apiBase}${endpoint}`, {
      signal,
      method: method,
      headers: {
        Accept: '*/*',
        'Content-Type': contentType,
        Authorization: token ? `Bearer ${token}` : getBearerToken(),
        ...headers,
      },
      body: file ? file : payload ? JSON.stringify(payload) : null,
    })

    clearTimeout(timeoutId);

    // Store response JSON
    let result = null
    if (!ignoreResponse) {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        result = await response.json()
      } else {
      
        result = response.body
      }
    }
    
    // Return data
    return {
      response,
      result,
    }
  }

  /**
   * Make API POST request
   */
  const makePostRequest = async (props) =>
    makeRequest({ ...props, method: 'POST' })

  /**
   * Make API GET request
   */
  const makeGetRequest = async (props) =>
    makeRequest({ ...props, method: 'GET' })

  /**
   * Make API PATCH request
   */
  const makePatchRequest = async (props) =>
    makeRequest({ ...props, method: 'PATCH' })

  /**
   * Set global state and push error back to parent
   * TODO: this should probably move into request provider
   */
  const throwGlobalError = (message, code = 'UNKNOWN_ERROR') => {
    const error = message?.message ? message.message : message
    setGlobalError(error)
    pushLinkError(error, { code })
  }

  /**
   * Push message to parent
   */
  const pushMessage = (payload) => {
    const origin = new URLSearchParams(window.location.search).get('origin')
    if (origin) window.parent.postMessage(payload, origin)
  }

  /**
   * Push init message to parent
   */
  const pushInit = (linkId) =>
    pushMessage({
      type: 'init',
      linkId: linkId ?? currentSession.linkId,
      environment: currentSession.applicationEnvironment,
    })

  /**
   * Push init message to parent
   * TODO: Check policy existence? Hopefully the server shouldn't guide us to the complete step without but best be safe
   */
  const pushSuccess = (update) =>
    pushMessage({
      type: 'success',
      linkId: currentSession.linkId,
      policies: update._embedded.policies,
    })

  /**
   * Pass message from server straight to parent
   */
  const pushLinkError = (message, details = {}) =>
    pushMessage({
      type: 'link_error',
      linkId: currentSession?.linkId,
      message,
      details,
    })

  /**
   * Pass current step back
   */
  const pushStepChange = (step) =>
    pushMessage({ type: 'step_change', linkId: currentSession.linkId, step })

  /**
   * Pass ineligable link back
   */
  const pushIneligibleLink = (policies) =>
    pushMessage({
      type: 'ineligible_link',
      linkId: currentSession.linkId,
      policies,
    })

  /**
   * Pass ineligable link back
   */
  const pushDocumentUpload = (location) =>
    pushMessage({
      type: 'document_upload',
      linkId: currentSession.linkId,
      location,
    })

  /**
   * Push close message to parent
   */
  const pushClose = () => pushMessage({ type: 'close' })

  /**
   * Push missing provider message to parent
   */
  const pushMissingProvider = (searchString) =>
    pushMessage({
      type: 'missing_provider',
      linkId: currentSession.linkId,
      searchString,
    })

  const providerValue = {
    makePostRequest,
    makeGetRequest,
    makePatchRequest,
    pushStepChange,
    pushLinkError,
    pushSuccess,
    pushInit,
    pushClose,
    pushIneligibleLink,
    pushDocumentUpload,
    pushMissingProvider,
    throwGlobalError,
    getBearerToken,
  }

  return (
    <RequestsContext.Provider value={providerValue}>
      {children}
    </RequestsContext.Provider>
  )
}
