import posthog from 'posthog-js'
import { parseCookies, setCookie } from 'nookies'
import { createContext, useContext, useState, useRef, useEffect } from 'react'
import { generateSessionID } from './utils'

/**
 * Create the context
 */
const AnalyticsContext = createContext()

/**
 * Create hook
 */
export const useAnalytics = () => {
  return useContext(AnalyticsContext)
}

/**
 * Create provider
 */
export const AnalyticsProvider = ({ children }) => {
  // Local state
  const [ready, setReady] = useState(null)
  const [offlineQueue, setOfflineQueue] = useState([])
  const [posthogApiKey, setPosthogApiKey] = useState(null)

  // Queue ref - we need this as the PH loaded callback can only access initial state
  const offlineQueueRef = useRef(offlineQueue)

  // Keep track offline qeueue
  useEffect(() => {
    offlineQueueRef.current = offlineQueue
  }, [offlineQueue])

  // Loop through offlineQueue when postHog is ready
  useEffect(() => {
    // Process offline queue
    const queue = offlineQueueRef.current
    if (queue.length > 0) {
      // Log and capture
      for (const { title, payload } of queue) {
        capture(title, payload)
      }

      // Clear offline queue and log
      setOfflineQueue([])
    }
  }, [ready])

  /**
   * Load analytics
   *
   * We can add other providers here if we need to
   */
  const init = async (integrationId, environment, height, width, linkId) => {
    let payload = {}

    // Append environment regardless if it's null
    payload['environment'] = environment

    // Check if there is an integrationId
    if (integrationId) payload['integration'] = integrationId

    // Check if height was declared
    if (height) payload['embedHeight'] = height

    // Check if width was declared
    if (width) payload['embedWidth'] = width

    // Check for valid API key
    if (posthogApiKey && !ready) {
      try {
        // Initialize Posthog
        posthog.init(posthogApiKey, {
          api_host: 'https://analytics.covie.io',
          autocapture: false,
          capture_pageview: false,
          loaded: function (posthog) {
            // Check for existing user ID or create a new one
            const cookies = parseCookies()
            const uuid = cookies.covie_uuid ?? generateSessionID()
            if (!cookies.covie_uuid) {
              setCookie(null, 'covie_uuid', uuid, {
                maxAge: 5 * 24 * 60 * 60,
                path: '/',
              })
            }

            // Register payload against events
            posthog.register(payload)

            // Identify user with linkId and payload
            posthog.identify(linkId, payload)

            // Set ready flag
            setReady(true)
          },
        })
      } catch (e) {
        console.log(e)

        // Show notice
        console.warn('Analytics not running in this environment')
      }
    }
  }

  /**
   * Capture event
   *
   * If we wish to implement other Analytics providers down the line
   * we should seperate them into individual capture functions for finer control
   */
  const capture = (title, payload) => {
    // Trigger posthog event (this should quietly fail if we haven't intialized)
    if (ready) posthog.capture(title, payload)
    // Push to queue if we're not ready
    else setOfflineQueue([...offlineQueue, { title, payload }])
  }

  /**
   * Open link
   *
   * Opens a link and triggers and event
   */
  const openAndCaptureLink = (href, title, payload) => {
    // Capture event
    capture(title, payload)

    // Open link in new tab
    window.open(href, '_blank')
  }

  // Reference what we want to pass back to the provider
  const value = {
    init,
    capture,
    openAndCaptureLink,
    posthogApiKey,
    setPosthogApiKey,
  }

  /**
   * Render
   *
   * Here we return the provider with any methods/vars we want public access to with the value prop
   */
  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}
