import Head from 'next/head'
import { AnalyticsProvider } from 'providers/analytics'
import { AppProvider } from 'providers/app'
import { RequestsProvider } from 'providers/requests'
import { RecoilRoot } from 'recoil'

import 'styles/index.css'
/**
 * This is the entry point into the app
 */
export default function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Covie Access Embed</title>
        <link rel="icon" href="/favicon-16x16.png" />
      </Head>
      <RecoilRoot>
        <RequestsProvider>
          <AnalyticsProvider>
            <AppProvider>
              <Component {...pageProps} />
            </AppProvider>
          </AnalyticsProvider>
        </RequestsProvider>
      </RecoilRoot>
    </>
  )
}
