import { atom } from 'recoil'

/**
 * This registers the required atoms which make up the link state
 */

export const apiBaseState = atom({
  key: 'apiBaseState',
  default: 'https://api.covie.io',
})

export const currentSessionState = atom({
  key: 'currentSessionState',
  default: null,
})

export const currentUpdateState = atom({
  key: 'currentUpdateState',
  default: null,
})

export const disclosureCompletedState = atom({
  key: 'disclosureCompletedState',
  default: false,
})

export const providerNotFoundState = atom({
  key: 'providerNotFoundState',
  default: false,
})

export const hideState = atom({
  key: 'hideState',
  default: [],
})
