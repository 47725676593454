import { createContext, useContext, useEffect, useState } from 'react'
import tinycolor from 'tinycolor2'

const AppContext = createContext()

export const useApp = () => {
  return useContext(AppContext)
}

export const AppProvider = ({ children }) => {
  // Local state
  const [brand, setBrand] = useState(null)
  const [primaryColor, setPrimaryColor] = useState(null)
  const [primaryTextStyle, setPrimaryTextStyle] = useState(null)
  const [primaryBackgroundStyle, setPrimaryBackgroundStyle] = useState(null)

  // On brand change, calculate styles
  useEffect(() => {
    if (brand) {
      setPrimaryColor(brand?.colors?.primary)
      setPrimaryTextStyle({ color: brand?.colors?.primary })
      setPrimaryBackgroundStyle({
        backgroundColor: brand?.colors?.primary,
        color: tinycolor(brand?.colors?.primary).isLight() ? 'black' : 'white',
        borderColor: tinycolor(brand?.colors?.primary).isLight()
          ? '#333'
          : brand?.colors?.primary,
      })
    }
  }, [brand])

  const appValue = {
    brand,
    setBrand,
    primaryColor,
    primaryTextStyle,
    primaryBackgroundStyle,
  }

  return <AppContext.Provider value={appValue}>{children}</AppContext.Provider>
}
