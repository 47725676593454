import { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

/**
 * useInterval wrapper to account for re-renders
 * @param {*} callback
 * @param {*} delay
 */
export const useInterval = (callback, delay) => {
  // Create ref
  const intervalRef = useRef()

  // Remember the latest callback
  useEffect(() => {
    intervalRef.current = callback
  }, [callback])

  // Set up the interval
  useEffect(() => {
    // Check we have a valid delay period
    if (typeof delay === 'number') {
      // Store the interval instance
      const instance = setInterval(() => {
        intervalRef.current()
      }, delay)

      // On dismount, clear the interval instance
      return () => clearInterval(instance)
    }
  }, [delay])
}

/**
 * useTimeout wrapper to account for re-renders
 * @param {*} callback
 * @param {*} delay
 */
export const useTimeout = (callback, delay) => {
  // Create ref
  const timeoutRef = useRef()

  // Remember the latest callback
  useEffect(() => {
    timeoutRef.current = callback
  }, [callback])

  // Set up the timeout
  useEffect(() => {
    // Check we have a valid delay period
    if (typeof delay === 'number') {
      // Store the timeout instance
      const instance = setTimeout(() => {
        timeoutRef.current()
      }, delay)

      // On dismount, clear the timeout instance
      return () => clearTimeout(instance)
    }
  }, [delay])
}

/**
 * Generate session ID
 */
export const generateSessionID = () => {
  const UUID = uuidv4()
  return UUID.replace(/-/g, '');
}

/**
 * Link-ify strings with urls in them
 */
export const parseServerMessage = (string) => {
  // Regex for url
  const urlRegex = /(https?:\/\/[^\s]+)/g

  // If regex in string
  if (urlRegex.test(string)) {
    // Split string on regex, map over and set anchor tag for url, span for the rest
    return (
      <div>
        {string.split(urlRegex).map((substring) => {
          if (urlRegex.test(substring))
            return (
              <a
                className="font-semibold underline"
                target="_blank"
                rel="noreferrer"
                href={substring}
              >
                {substring}
              </a>
            )
          return <span key={substring}>{substring}</span>
        })}
      </div>
    )
  }
  // Return string
  return string
}
