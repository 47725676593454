import { atom } from 'recoil'

/**
 * This registers the required atoms which make up the form state
 */

export const localErrorState = atom({
  key: 'localErrorState',
  default: null,
})

export const globalErrorState = atom({
  key: 'globalErrorState',
  default: null,
})

export const globalLoadingState = atom({
  key: 'globalLoadingState',
  default: false,
})
