import Link from 'next/link'

export default function App() {
  return (
    <div className="mx-auto max-w-4xl p-8">
      <h2 className="mb-4 text-3xl font-bold">Covie Access</h2>
      <p className="text-gray-500">
        Looking for{' '}
        <Link href="/demo" passHref className="text-blue-500">
          a demo
        </Link>{' '}
        on how to embed the Covie app?
      </p>
    </div>
  );
}

/**
 * Declared to avoid issue of router params not resolving
 */
export async function getStaticProps(context) {
  return {
    props: {},
  }
}
